import { DateTime } from 'luxon';

import {
    RequestedBy,
    ShiftRequestAbbrDto,
    ShiftRequestStatus,
} from '@clh/api-client';

import './requested-by-label.less';

const RequestedByLabel = (request: ShiftRequestAbbrDto) => {
    return (
        <>
            <span className="yellow-circle me-1"></span>
            <span className="text-uppercase fw-semibold">
                Requested by{' '}
                {request.requestedBy === RequestedBy.Facility
                    ? 'facility'
                    : 'you'}
            </span>
            {request.status === ShiftRequestStatus.Declined && (
                <span className="fst-italic ms-2">
                    (Declined
                    {request.updatedAt &&
                        ` on ${DateTime.fromJSDate(
                            request.updatedAt
                        ).toLocaleString(DateTime.DATE_SHORT)}`}
                    )
                </span>
            )}
        </>
    );
};

export default RequestedByLabel;
