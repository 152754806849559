// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yellow-circle {
  border-radius: 100%;
  background-color: var(--bs-yellow);
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/shared-components/shift-details/requested-by-label.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kCAAA;EACA,aAAA;EACA,cAAA;EACA,qBAAA;AACJ","sourcesContent":[".yellow-circle {\n    border-radius: 100%;\n    background-color: var(--bs-yellow);\n    width: .8rem;\n    height: .8rem;\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
