import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ShiftDto, ShiftSearchResultItemDto } from '@clh/api-client';
import {
    CostCalculator,
    CalculatedCosts,
    calculateTotalStipend,
    toDollarAmount,
    CalculatedRates,
    calculateTotalPay,
} from '@clh/util';

import { getShiftInfo, getShiftSchedule } from '../../helpers';
import Image from '../../shared-components/image';
import FacilityVerificationBadge from '../facility-verification-badge';
import RequestedByLabel from '../shift-details/requested-by-label';

interface ShiftData {
    title: string;
    facilityName: string;
    schedule: string;
    lengthInMinutes: number;
    payRate: string;
    totalRate: string;
    address: string;
    description?: string | null;
    costs: {
        estimate: CalculatedCosts;
        actual?: CalculatedCosts;
        rates: CalculatedRates;
    };
    stipendMinimumDistance?: number;
}

interface ShiftViewProps {
    shift: ShiftSearchResultItemDto;
    isMyShift?: boolean;
}

export function getShiftData(shift: Omit<ShiftDto, 'nurseProfile'>): ShiftData {
    const { lengthInMinutes } = getShiftInfo(
        shift.shiftTime,
        shift.startTime,
        shift.endTime
    );

    const stipend = calculateTotalStipend(shift?.stipends);

    const estimate = CostCalculator.getTotalCosts(
        shift.totalRate,
        shift.platformFeePct,
        shift.employerFeePct,
        lengthInMinutes,
        stipend
    );

    const actual = shift.minutesWorked
        ? CostCalculator.getTotalCosts(
              shift.totalRate,
              shift.platformFeePct,
              shift.employerFeePct,
              shift.minutesWorked,
              stipend
          )
        : undefined;

    const rates = CostCalculator.getCostsPerHour(
        shift.totalRate,
        shift.platformFeePct,
        shift.employerFeePct
    );

    return {
        title: shift.specialty.name,
        facilityName: shift.facility.name,
        schedule: getShiftSchedule(shift),
        lengthInMinutes,
        payRate: `${toDollarAmount(shift.payRate)}/hr`,
        totalRate: `${toDollarAmount(shift.totalRate)}/hr`,
        address: `${shift.facility.address?.city}, ${shift.facility.address?.state}`,
        description: shift.description,
        costs: {
            estimate,
            actual,
            rates,
        },
        stipendMinimumDistance: shift.stipendMinimumDistance,
    };
}

function Shift({ shift, isMyShift }: ShiftViewProps) {
    const stipends = shift.isQualifiedForStipend ? shift.stipends : [];

    const shiftData = getShiftData({
        ...shift,
        stipends,
    });

    const totalStipend = calculateTotalStipend(stipends);
    const totalEstimate = calculateTotalPay(
        shift.payRate,
        shiftData.lengthInMinutes,
        totalStipend
    );

    return (
        <Link
            className="mb-3 badge bg-light text-dark text-decoration-none"
            to={
                isMyShift
                    ? `/site/my-schedule/shift/${shift.id}`
                    : `/site/browse-shifts/${shift.id}`
            }
        >
            <div className="row p-2 p-md-0 p-md-2-end">
                <div className="d-none d-md-block col-3 col-lg-2 align-self-center">
                    <Image
                        style={{ maxHeight: '200px' }}
                        src={shift.facility.photoUrl}
                        fallback={
                            <div
                                className="card d-flex justify-content-center align-items-center"
                                style={{
                                    height: '100px',
                                }}
                            >
                                <i className="bi bi-camera fs-1" />
                            </div>
                        }
                    />
                </div>
                <div className="col-8 col-md-5 col-lg-6 text-start text-wrap p-md-2">
                    {shift.request && <RequestedByLabel {...shift.request} />}
                    <h3 className="h5">{shiftData.title}</h3>
                    <div className="mb-1 d-flex align-items-center">
                        <span className="fs-6 fw-normal">
                            {shiftData.facilityName}
                        </span>
                        <FacilityVerificationBadge
                            facilityId={shift.facility.id}
                        />
                    </div>
                    <div className="fs-6 mb-1 fw-normal">
                        {shiftData.schedule}
                    </div>
                    <div className="fs-6 mb-1 fw-normal">
                        {shiftData.address}
                    </div>
                </div>
                <div className="col-4 col-4 text-end fw-normal p-md-2 pe-md-3">
                    <div className="h4">{toDollarAmount(totalEstimate)}</div>
                    <div
                        className={classNames('fs-6', {
                            'mb-2': totalStipend > 0,
                        })}
                    >
                        {shiftData.payRate}
                    </div>
                    {totalStipend > 0 && (
                        <div className="fs-6">
                            Includes {toDollarAmount(totalStipend)} Stipend
                        </div>
                    )}
                </div>
            </div>
        </Link>
    );
}

export default Shift;
